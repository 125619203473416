import MessageMap from '../i18n.d';

const esES: MessageMap = {
  test: {
    translate: 'Traduzir',
    missing: 'Disponível apenas a opção em inglês'
  },
  Breadcrumb: {
    home: 'Lista de reglas',
    create: 'Crear regla',
    earning: 'Earning',
    exclusion: 'Ganancias'
  },
  Buttons: {
    back: 'Volver atrás',
    continue: 'Continuar',
    createNewRuleBtn: 'Crear regla',
    goToAudiencesBtn: 'Ir a Audiencias'
  },
  createRulePage: {
    title: 'Tipo de regla',
    description: 'Selecciona un tipo de acuerdo con los objetivos de creación de reglas.',
    earningRuleTitle: 'Ganancias',
    earningRuleDescription: 'Esta regla permite que todos los POC registrados en el {tier} ganen puntos por compras elegibles.',
    exclusionRuleTitle: ' Exclusión',
    exclusionRuleDescription: 'Esta regla impide que los POC acumulen puntos a través de las reglas de ganancia, a la vez que los mantienen en el {tier}.'
  },
  modal: {
    confirmLabel: 'Sí, salir',
    cancelLabel: 'No, volver',
    title: 'Eliminar regla?',
    body: 'No puedes deshacer esta acción.',
  },
  Rules: {
    earningList: 'Lista de ganancias',
    earningName: '{point} points for every {amountSpent} spent',
    earningRulesDescription:
      'Estas reglas permiten que todos los POC registrados en el {tier} ganen puntos por compras elegibles.',
    exclusionList: 'Lista de exclusiones',
    exclusionListDescription:
      'Estas reglas evitan que los POC acumulen puntos a través de las reglas de ganancia, a la vez que los mantienen en el {tier}.'
  },
  TablesRules: {
    searchByDescription: 'Buscar por descripción',
    ruleDescription: 'Descripción de reglas',
    editedOn: 'Editado el',
    status: 'Estado',
    draft: 'Borrador',
    active: 'Activo',
    audience: 'Audiencia'
  },
  EarningRulesTable: {
    amount: '{amountSpent}',
    searchByDescription: 'Buscar por descripción',
    ruleDescription: 'Descripción de reglas"',
    points: 'Puntos',
    currencyAmount: 'Monto en la moneda correspondiente',
    skus: 'SKUs',
    editOn: 'Editado el',
    status: 'Status'
  }
};

export default esES;
