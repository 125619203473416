import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LoadingDots } from '@hexa-ui/components';

const RulesTablesPage = lazy(() => import('./pages/Rules/RulesTables/RulesTables'));
const CreateRule = lazy(() => import('./pages/Rules/CreateRule/CreateRule'));
const CreateEarningRule = lazy(() => import('./pages/Rules/CreateEarningRule/CreateEarningRule'));
const CreateExclusionRule = lazy(
  () => import('./pages/Rules/CreateExclusionRule /CreateExclusionRule ')
);

export const PARENT_BASEPATH = '/membership';
export const BASEPATH = `${PARENT_BASEPATH}/rules-v2`;
export const CREATE_RULE = `${BASEPATH}/create-rule`;
export const EARNING_RULE = `${BASEPATH}/earning-rule`;
export const EXCLUSION_RULE = `${BASEPATH}/exclusion-rule`;

export function RouterContent() {
  return (
    <Suspense fallback={<LoadingDots />}>
      <Routes>
        <Route path={BASEPATH} index element={<RulesTablesPage />} />
        <Route path={`${CREATE_RULE}`} element={<CreateRule />} />
        <Route path={`${EARNING_RULE}/:id?`} element={<CreateEarningRule />} />
        <Route path={`${EXCLUSION_RULE}/:id?`} element={<CreateExclusionRule />} />
      </Routes>
    </Suspense>
  );
}

function Router() {
  return (
    <BrowserRouter>
      <RouterContent />
    </BrowserRouter>
  );
}

export default Router;
