import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available'
  },
  Breadcrumb: {
    home: 'Rules list',
    create: 'Create Rule',
    earning: 'Earning',
    exclusion: 'Exclusion'
  },
  Buttons: {
    back: 'Go back',
    continue: 'Continue',
    createNewRuleBtn: 'Create rule',
    goToAudiencesBtn: 'Go to Audiences'
  },
  createRulePage: {
    title: 'Rule type',
    description: 'Select a type according to the goals of rule creation.',
    earningRuleTitle: 'Earning',
    earningRuleDescription: 'This rule allows all POCs registered in {tier} to earn points by eligible purchases.',
    exclusionRuleTitle: ' Exclusion',
    exclusionRuleDescription: 'This rule prevents POCs from accumulating points through earning rules, while keeping them in {tier}.'
  },
  modal: {
    confirmLabel: 'Yes, leave',
    cancelLabel: 'No, go back',
    title: 'Leave rule creation?',
    body: 'All unsaved changes will be lost.',
  },
  Rules: {
    earningName: '{point} points for every {amountSpent} spent',
    earningList: 'Earning list',
    earningRulesDescription:
      'These rules allow all POCs registered in {tier} to earn points by eligible purchases.',

    exclusionList: 'Exclusion list',
    exclusionListDescription:
      'These rules prevent POCs from accumulating points through earning rules, while keeping them in {tier}.'
  },
  TablesRules: {
    searchByDescription: 'Search by description',
    ruleDescription: 'Rule description',
    editedOn: 'Edited on',
    status: 'Status',
    draft: 'Draft',
    active: 'Active',
    audience: 'Audience'
  },
  EarningRulesTable: {
    amount: '{amountSpent}',
    searchByDescription: 'Search by description',
    ruleDescription: 'Rule description',
    points: 'Points',
    currencyAmount: 'Currency amount',
    skus: 'SKUs',
    editOn: 'Edited on',
    status: 'Status'
  }
};

export default enUS;
