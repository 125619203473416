import { Grid } from '@hexa-ui/components';
import { StylesProvider, createGenerateClassName } from '@material-ui/core';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from './config/queryClient';
import { IntlProvider } from './i18n';
import { EnvConfig, EnvProvider, useEnvContext } from './provider/EnvProvider/EnvProvider';
import Router from './Router';
import { initFeatureToggles } from './utils/featureToggleHelper/featureToggleHelper';

export default function App(props: Readonly<EnvConfig>) {
  const { optimizelyKey } = props;

  initFeatureToggles(optimizelyKey);

  useEnvContext();

  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-one-rewards-rules-admin-mfe',
    seed: 'bees-hub-one-rewards-rules-admin-mfe'
  });

  return (
    <EnvProvider env={props}>
      <QueryClientProvider client={queryClient}>
        <StylesProvider generateClassName={generateClassName}>
          <IntlProvider>
            <Grid.Container
              style={{
                rowGap: '1rem',
                display: 'block'
              }}
              type="fluid"
              sidebar
            >
              <Router />
            </Grid.Container>
          </IntlProvider>
        </StylesProvider>
      </QueryClientProvider>
    </EnvProvider>
  );
}
