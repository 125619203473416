import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  test: {
    translate: 'Traduzir',
    missing: 'Disponível apenas a opção em inglês'
  },
  Breadcrumb: {
    home: 'Lista de regras',
    create: 'Criar regra',
    earning: 'Ganhos',
    exclusion: 'Exclusão'
  },
  Buttons: {
    back: 'Voltar',
    continue: 'Continuar',
    createNewRuleBtn: 'Criar regra',
    goToAudiencesBtn: 'Ir para Públicos'
  },
  createRulePage: {
    title: 'Tipo de regra',
    description: 'Selecione um tipo de acordo com as metas de criação de regras.',
    earningRuleTitle: 'Ganhos',
    earningRuleDescription: 'Essa regra permite que todos os PDVs registrados no {tier} ganhem pontos por compras qualificadas.',
    exclusionRuleTitle: 'Exclusão',
    exclusionRuleDescription: 'Essa regra impede que os PDVs acumulem pontos por meio de regras de ganho, enquanto permanecem no {tier}.'
  },
  modal: {
    confirmLabel: 'Sim, sair',
    cancelLabel: 'Não, voltar',
    title: 'Sair da criação da regra?',
    body: 'Todas as alterações não salvas serão perdidas.',
  },
  Rules: {
    earningList: 'Lista de ganhos',
    earningName: '{point} points for every {amountSpent} spent',
    earningRulesDescription:
      'Essas regras permitem que todos os PDVs registrados no {tier} ganhem pontos por compras qualificadas.',

    exclusionList: 'Lista de exclusão',
    exclusionListDescription:
      'Essas regras impedem que os PDVs acumulem pontos por meio de regras de ganho, enquanto permanecem no {tier}.'
  },
  TablesRules: {
    searchByDescription: 'Pesquisar por descrição',
    ruleDescription: 'Descrição da regra',
    editedOn: 'Editado em',
    status: 'Status',
    draft: 'Rascunho',
    active: 'Ativo',
    audience: 'Público'
  },
  EarningRulesTable: {
    amount: '{amountSpent}',
    searchByDescription: 'Pesquisar por descrição',
    ruleDescription: 'Descrição da regra',
    points: 'Pontos',
    currencyAmount: 'Valor da moeda',
    skus: 'SKUs',
    editOn: 'Editado em',
    status: 'Status'
  }
};

export default ptBR;
